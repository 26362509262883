.separatorx {
  height: 5px;
  background-color: rgb(175, 122, 15);
  margin: 40px 0px;
}

.btn-goto label {
  font-size: 12px;
  margin-top: 0px;
  cursor: pointer;
}

.btn-goto {
  background-color: rgb(175, 122, 15);
  width: -moz-max-content;
  width: max-content;
  padding: 10px 17px;
  color: white;
  font-weight: 700;
  cursor: pointer;
  transition: 0.9s;
  margin-top: 0px;
  display: flex;
  align-items: center;
  gap: 10px;
  border: 1px solid transparent;
}

.btn-goto-reverse:hover {
  background-color: rgba(24, 166, 24, 0.821);
  border: 1px solid white;
  color: white;
}

.btn-goto-reverse label {
  font-size: 12px;
  margin-top: 0px;
  cursor: pointer;
}

.btn-goto-reverse {
  background-color: white;
  border: 1px solid rgb(175, 122, 15);
  color: rgb(175, 122, 15);
  width: -moz-max-content;
  width: max-content;
  padding: 10px 17px;
  font-weight: 700;
  cursor: pointer;
  transition: 0.9s;
  margin-top: 0px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.btn-goto:hover {
  background-color: white;
  border: 1px solid rgb(175, 122, 15);
  color: rgb(175, 122, 15);
}

.products-all {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(241, 241, 241);
  padding: 20px 0px;
}
.products-all p {
  width: 430px;
  line-height: 23px;
  font-size: 13px;
  text-align: center;
}

.products-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  padding: 20px 0px;
}

.product:hover {
  border-bottom: 6px solid rgb(175, 122, 15);
  border-top: 6px solid transparent;
}
.product:hover .content {
  display: flex;
}

.product {
  width: 200px;
  height: 220px;
  text-align: center;
  background-color: white;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  border-bottom: 6px solid transparent;
  border-top: 6px solid rgb(175, 122, 15);
  transition: 0.9s;
  cursor: pointer;
}
.product .content {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.769) !important;
  display: none;
  justify-content: start;
  align-items: start;
  flex-direction: column;
  transition: 0.9s;
}
.product .content .name {
  color: rgb(175, 122, 15);
  margin: 10px 10px;
  font-weight: 700;
}

.software {
  background-image: url(../ASSETS/globe.png);
  background-size: auto;
  background-repeat: no-repeat;
  background-color: rgb(255, 255, 255);
}

.main-header {
  position: relative;
  width: 100%;
  height: 85vh;
  overflow: hidden;
}
.main-header .bg-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.main-header .menu-phone {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
}
.main-header .menu-phone .item-one {
  padding: 20px 0px;
  background-color: rgba(1, 1, 1, 0.6);
  transition: 0.9s;
  display: flex;
  justify-content: space-between;
}
.main-header .menu-phone .item-one .one {
  padding: 0px 30px;
  display: grid;
  place-content: center;
}
.main-header .menu-phone .item-one .one .icon {
  color: white;
  cursor: pointer;
}
.main-header .menu-phone .item-one .one .logo {
  background-color: transparent !important;
}
.main-header .menu-phone .item-two {
  background-color: white;
  flex-direction: column;
  transition: 0.9s;
}
.main-header .menu-phone .item-two .item {
  padding: 20px 20px;
  font-size: 13px;
  font-weight: 400;
  cursor: pointer;
  border: 0.3px solid rgb(195, 193, 193);
  transition: 1s;
  font-weight: 900;
}
.main-header .menu-phone .item-two .item:hover {
  background-color: rgb(175, 122, 15);
  color: white;
  font-weight: 400;
}
.main-header .menu-desktop {
  position: fixed;
  top: 0;
  background-color: rgba(10, 9, 9, 0.438);
  width: 100%;
  padding: 20px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 10;
}
.main-header .menu-desktop .logo {
  margin: 0px 30px;
}
.main-header .menu-desktop .menu-all {
  margin: 0px 30px;
  display: flex;
}
.main-header .menu-desktop .menu-all .item {
  padding: 7px 10px;
  font-size: 11px;
  font-weight: 400;
  cursor: pointer;
  color: white;
  transition: 1s;
  border-radius: 2px;
}
.main-header .menu-desktop .menu-all .item:hover {
  background-color: rgba(255, 255, 255, 0.335);
  color: white;
}
.main-header .menu-desktop .menu-all .active {
  background-color: rgba(255, 255, 255, 0.255);
  color: white;
  font-weight: 900;
}
.main-header .content-menu {
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;
}
.main-header .content-menu .title-one {
  color: white;
}
.main-header .content-menu .title-two {
  color: white;
}
.main-header .content-menu .title-three {
  color: white;
  letter-spacing: 1px;
}
.main-header .content-menu .btn-four {
  color: white;
  margin: 30px;
  background-color: rgba(240, 248, 255, 0.288);
  padding: 18px;
  font-size: 12px;
  font-weight: 700;
  cursor: pointer;
  transition: 0.9s;
}
.main-header .content-menu .btn-four:hover {
  background-color: rgba(1, 1, 1, 0.288);
  border-radius: 8px;
}

.tech-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 50px 0px;
}
.tech-container .tech-image img {
  width: 530px;
  height: 400px;
  -o-object-fit: cover;
     object-fit: cover;
}
.tech-container .tech-text {
  width: 530px;
  height: 400px;
  line-height: 23px;
  font-size: 13px;
  background-color: rgb(175, 122, 15);
}
.tech-container .tech-text h2 {
  margin: 18px;
  color: white;
}
.tech-container .tech-text .separatorx {
  background-color: white;
  margin: 24px;
}
.tech-container .tech-text p {
  margin: 0px 18px;
  color: white;
}
.tech-container .tech-text .btn-goto-reverse {
  margin: 38px 18px;
}

.main-service {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100vw;
  flex-wrap: wrap;
  gap: 20px;
  background-color: rgb(243, 243, 243);
  margin: 50px 0px;
  padding: 50px 0px;
}
.main-service .item:hover {
  border: 0.5px solid rgb(175, 122, 15);
  border-bottom: 4px solid rgb(214, 217, 9);
  background-color: white;
}
.main-service .item:hover .title {
  color: rgb(175, 122, 15);
}
.main-service .item:hover .separatorx {
  width: 160px;
  background-color: rgb(214, 217, 9);
}
.main-service .item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 18px 10px;
  border: 0.5px solid rgb(175, 122, 15);
  border-top: 4px solid rgb(175, 122, 15);
  border-radius: 1px;
  width: 260px;
  cursor: pointer;
  transition: all 1s ease;
  background-color: rgb(200, 200, 200);
}
.main-service .item .separatorx {
  width: 90px;
  transition: 0.9s;
}
.main-service .item .icon {
  font-size: 24px;
  background-color: rgb(175, 122, 15);
  height: 70px;
  width: 70px;
  display: grid;
  place-content: center;
  border-radius: 100%;
  color: white;
}
.main-service .item .title {
  margin: 20px 10px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  width: 230px;
  transition: 1s;
}
.main-service .item .content {
  margin-top: 10px;
  text-align: center;
  font-size: 13px;
  color: gray;
  line-height: 20px;
}

.footer-all {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: black;
}
.footer-all .footer-one {
  padding: 20px;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
}
.footer-all .footer-one .one {
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer-all .footer-one .one .text {
  font-size: 12px;
  color: rgb(192, 192, 192) !important;
  border-left: 1px solid gray;
  padding: 0px 20px;
  font-weight: 300;
  line-height: 20px;
}
.footer-all .footer-one .footer-menu {
  display: flex;
}
.footer-all .footer-one .footer-menu .item {
  color: white;
  font-size: 11px;
  font-weight: 800;
  padding: 10px;
  background-color: rgba(219, 242, 222, 0.184);
  margin: 0px 2px;
  cursor: pointer;
}
.footer-all .footer-one .footer-menu .item:hover {
  background-color: transparent;
}
.footer-all .footer-two {
  font-size: 13px;
  padding: 20px;
  color: rgb(192, 192, 192) !important;
  border-top: rgb(68, 68, 68) solid 1.2px;
}
.footer-all .footer-two .company {
  color: rgb(175, 122, 15);
  cursor: pointer;
}
.footer-all .social {
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.9s;
}
.footer-all .social .icon {
  margin: 5px 10px;
  font-size: 20px;
  cursor: pointer;
}
.footer-all .social .icon:hover {
  color: greenyellow;
}

.contacts {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: black;
  justify-content: center;
}
.contacts h2 {
  color: white;
}
.contacts .separatorx {
  background-color: white;
}
.contacts p {
  font-size: 13px;
  width: 700px;
  line-height: 22px;
  text-align: center;
  color: white;
}

.contact-info {
  margin: 20px;
  padding: 10px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
}

.contact-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  margin: 10px 30px;
}
.contact-item img {
  height: 60px;
  border-radius: 100px;
  background-color: rgb(175, 122, 15);
  border: 3px solid rgb(239, 239, 239);
}
.contact-item h4 {
  font-weight: 600;
  margin-top: 10px;
  color: white;
  width: 200px;
  text-align: center;
  padding: 10px;
  margin-bottom: 40px;
}

.testimonies {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0px;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: black;
  width: 100vw;
  position: relative;
}
.testimonies p {
  font-size: 12px;
  line-height: 24px;
  color: white;
  margin: 20px;
  font-weight: 200;
}
.testimonies .text {
  background-color: transparent;
  border: 1px solid transparent;
}
.testimonies .text .title {
  font-size: 30px;
  font-weight: 700;
  color: white;
  margin: 20px;
}
.testimonies .text .btn-goto {
  margin: 10px 20px;
}
.testimonies img {
  height: 100%;
  width: 100%;
  position: absolute;
  opacity: 0.2;
}
.testimonies div {
  height: 300px;
  width: 330px;
  margin: 20px;
  z-index: 0;
  border: 1px solid white;
  border-radius: 7px;
  background: rgba(220, 220, 220, 0.1568627451);
}
.testimonies .testimony .stars {
  color: rgb(217, 151, 20);
  display: flex;
  margin: 40px 20px;
}
.testimonies .testimony .stars .icon {
  margin: 0px 5px;
}
.testimonies .testimony .customer {
  display: flex;
  margin: 0px 20px;
}
.testimonies .testimony .customer .avatar {
  height: 67px;
  width: 67px;
  border-radius: 100px;
  background-color: black;
  background-repeat: no-repeat;
  background-size: cover;
}
.testimonies .testimony .customer .id {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
}
.testimonies .testimony .customer .id .name {
  color: white;
  margin: 0px 10px;
  font-weight: 700;
}
.testimonies .testimony .customer .id .title {
  color: white;
  margin: 0px 10px;
  font-weight: 300;
  font-size: 13PX;
  margin-top: 4px;
}

.products-all {
  position: relative;
}

.device-details {
  position: fixed !important;
  background-color: rgba(0, 0, 0, 0.321);
  bottom: 0;
  z-index: 100;
  width: 100vw;
  height: 100vh;
}
.device-details .device-component {
  width: 430px;
  float: right;
  height: 70vw;
  margin: 30px 0px;
  background-color: white !important;
  overflow-y: auto;
  padding: 20px;
  border-radius: 10px;
}
.device-details .device-component .caracteristique {
  text-align: left;
  color: rgb(98, 98, 98);
  font-size: 11px;
}
.device-details .device-component .name {
  font-weight: 800;
  font-size: 25px;
  color: rgb(175, 122, 15);
}
.device-details .device-component .image {
  width: 100px;
}
.device-details .device-component .element {
  margin-bottom: 10px;
  font-size: 11px;
  color: rgb(98, 98, 98);
}
.device-details .device-component strong {
  color: black;
}

/* Media query for phones (up to 600px) */
@media only screen and (max-width: 600px) {
  .contacts p {
    width: auto;
    padding: 0px 30px;
  }
  .contact-info {
    flex-direction: column;
  }
  .contact-item img {
    height: 100px;
  }
  .main-header {
    background-color: rgb(5, 5, 5);
    height: 79vh;
  }
  .menu-desktop {
    display: none !important;
  }
  .content-menu {
    width: 100%;
  }
  .content-menu .title-one {
    font-size: 30px;
    letter-spacing: 2.5px;
    font-weight: 200;
    text-align: center;
  }
  .content-menu .title-two {
    font-size: 55px;
    font-weight: 900;
    letter-spacing: -1px;
    text-align: center;
  }
  .content-menu .title-three {
    line-height: 18px;
    font-size: 12px;
    width: 400px;
    text-align: center;
    margin-top: 13px;
  }
  .intro-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin: 60px 0px;
  }
  .intro-container .intro-image img {
    width: 300px;
    -o-object-fit: cover;
       object-fit: cover;
  }
  .intro-container .intro-text {
    width: 300px;
    line-height: 23px;
    font-size: 13px;
  }
  .tech-container {
    flex-direction: column;
  }
  .tech-container .tech-image img {
    width: 400px;
    height: 400px;
    -o-object-fit: cover;
       object-fit: cover;
  }
  .tech-container .tech-text {
    height: -moz-max-content;
    height: max-content;
    line-height: 23px;
    font-size: 13px;
    background-color: rgb(175, 122, 15);
    width: 400px;
  }
  .tech-container .tech-text h2 {
    margin: 18px 40px;
    color: white;
  }
  .tech-container .tech-text .separatorx {
    background-color: white;
    margin: 24px 40px;
  }
  .tech-container .tech-text p {
    margin: 0px 40px;
    color: white;
  }
  .tech-container .tech-text .btn-goto-reverse {
    margin: 38px 40px;
  }
  .tech-container .main-service {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100vw;
    flex-wrap: wrap;
    gap: 20px;
    background-color: rgb(243, 243, 243);
    margin: 50px 0px;
    padding: 50px 0px;
  }
  .tech-container .main-service .item:hover {
    border: 0.5px solid rgb(175, 122, 15);
    border-bottom: 4px solid rgb(214, 217, 9);
    background-color: white;
  }
  .tech-container .main-service .item:hover .title {
    color: rgb(175, 122, 15);
  }
  .tech-container .main-service .item:hover .separatorx {
    width: 160px;
    background-color: rgb(214, 217, 9);
  }
  .tech-container .main-service .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 18px 10px;
    border: 0.5px solid rgb(175, 122, 15);
    border-top: 4px solid rgb(175, 122, 15);
    border-radius: 1px;
    width: 260px;
    cursor: pointer;
    transition: all 1s ease;
    background-color: rgb(249, 247, 239);
  }
  .tech-container .main-service .item .separatorx {
    width: 90px;
    transition: 0.9s;
  }
  .tech-container .main-service .item .icon {
    font-size: 24px;
    background-color: rgb(175, 122, 15);
    height: 70px;
    width: 70px;
    display: grid;
    place-content: center;
    border-radius: 100%;
    color: white;
  }
  .tech-container .main-service .item .title {
    margin: 20px 10px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    width: 230px;
    transition: 1s;
  }
  .tech-container .main-service .item .content {
    margin-top: 10px;
    text-align: center;
    font-size: 13px;
    color: gray;
    line-height: 20px;
  }
  .tech-container .product {
    width: 300px;
    height: 280px;
  }
  .footer-all {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: s;
    background-color: black;
  }
  .footer-all .footer-one {
    flex-direction: column;
  }
  .footer-all .footer-one .one {
    display: flex;
    justify-content: start;
    align-items: start;
    flex-direction: column;
  }
  .footer-all .footer-one .one .text {
    font-size: 12px;
    color: rgb(192, 192, 192) !important;
    border-left: 1px solid transparent;
    padding: 20px;
    font-weight: 300;
    line-height: 20px;
    margin-left: 10px;
  }
  .footer-all .footer-one .footer-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  .footer-all .footer-one .footer-menu .item {
    width: 80%;
    color: white;
    font-size: 11px;
    font-weight: 800;
    padding: 20px;
    background-color: rgba(219, 242, 222, 0.184);
    margin: 1px;
    cursor: pointer;
  }
  .footer-all .footer-one .footer-menu .item:hover {
    background-color: transparent;
  }
  .footer-all .footer-two {
    font-size: 13px;
    padding: 20px;
    color: rgb(192, 192, 192) !important;
    border-top: rgb(68, 68, 68) solid 1.2px;
  }
  .footer-all .footer-two .company {
    color: rgb(175, 122, 15);
    cursor: pointer;
  }
  .testimonies {
    flex-direction: column;
  }
  .contact-info {
    flex-direction: column;
  }
}
/* Media query for tablets (601px to 900px) */
@media only screen and (min-width: 601px) and (max-width: 900px) {
  .main-header {
    background-color: rgb(7, 11, 7);
    height: 80vh;
  }
  .menu-desktop {
    display: none !important;
  }
  .content-menu {
    width: 100%;
  }
  .content-menu .title-one {
    font-size: 30px;
    letter-spacing: 2.5px;
    font-weight: 200;
    text-align: center;
  }
  .content-menu .title-two {
    font-size: 55px;
    font-weight: 900;
    letter-spacing: -1px;
    text-align: center;
  }
  .content-menu .title-three {
    line-height: 18px;
    font-size: 13px;
    width: 500px;
    text-align: center;
    margin-top: 13px;
  }
  .intro-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin: 60px 0px;
  }
  .intro-container .intro-image img {
    width: 430px;
    -o-object-fit: cover;
       object-fit: cover;
  }
  .intro-container .intro-text {
    width: 430px;
    line-height: 23px;
    font-size: 13px;
  }
  .tech-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 50px 0px;
  }
  .tech-container .tech-image img {
    width: 530px;
    height: 400px;
    -o-object-fit: cover;
       object-fit: cover;
  }
  .tech-container .tech-text {
    width: 530px;
    height: 400px;
    line-height: 23px;
    font-size: 13px;
    background-color: rgb(175, 122, 15);
  }
  .tech-container .tech-text h2 {
    margin: 18px;
    color: white;
  }
  .tech-container .tech-text .separatorx {
    background-color: white;
    margin: 24px;
  }
  .tech-container .tech-text p {
    margin: 0px 18px;
    color: white;
  }
  .tech-container .tech-text .btn-goto-reverse {
    margin: 38px 18px;
  }
  .main-service {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100vw;
    flex-wrap: wrap;
    gap: 20px;
    background-color: rgb(243, 243, 243);
    margin: 50px 0px;
    padding: 50px 0px;
  }
  .main-service .item:hover {
    border: 0.5px solid rgb(175, 122, 15);
    border-bottom: 4px solid rgb(214, 217, 9);
    background-color: white;
  }
  .main-service .item:hover .title {
    color: rgb(175, 122, 15);
  }
  .main-service .item:hover .separatorx {
    width: 160px;
    background-color: rgb(214, 217, 9);
  }
  .main-service .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 18px 10px;
    border: 0.5px solid rgb(175, 122, 15);
    border-top: 4px solid rgb(175, 122, 15);
    border-radius: 1px;
    width: 260px;
    cursor: pointer;
    transition: all 1s ease;
    background-color: rgb(249, 247, 239);
  }
  .main-service .item .separatorx {
    width: 90px;
    transition: 0.9s;
  }
  .main-service .item .icon {
    font-size: 24px;
    background-color: rgb(175, 122, 15);
    height: 70px;
    width: 70px;
    display: grid;
    place-content: center;
    border-radius: 100%;
    color: white;
  }
  .main-service .item .title {
    margin: 20px 10px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    width: 230px;
    transition: 1s;
  }
  .main-service .item .content {
    margin-top: 10px;
    text-align: center;
    font-size: 13px;
    color: gray;
    line-height: 20px;
  }
  .footer-all {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: s;
    background-color: black;
  }
  .footer-all .footer-one {
    flex-direction: column;
  }
  .footer-all .footer-one .one {
    display: flex;
    justify-content: start;
    align-items: start;
    flex-direction: column;
  }
  .footer-all .footer-one .one .text {
    font-size: 12px;
    color: rgb(192, 192, 192) !important;
    border-left: 1px solid transparent;
    padding: 20px;
    font-weight: 300;
    line-height: 20px;
    margin-left: 10px;
  }
  .footer-all .footer-one .footer-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  .footer-all .footer-one .footer-menu .item {
    width: 80%;
    color: white;
    font-size: 11px;
    font-weight: 800;
    padding: 20px;
    background-color: rgba(219, 242, 222, 0.184);
    margin: 1px;
    cursor: pointer;
  }
  .footer-all .footer-one .footer-menu .item:hover {
    background-color: transparent;
  }
  .footer-all .footer-two {
    font-size: 13px;
    padding: 20px;
    color: rgb(192, 192, 192) !important;
    border-top: rgb(68, 68, 68) solid 1.2px;
  }
  .footer-all .footer-two .company {
    color: rgb(175, 122, 15);
    cursor: pointer;
  }
  .testimonies {
    flex-direction: column;
  }
  .contact-info {
    flex-direction: column;
  }
}
/* Media query for desktops (901px and above) */
@media only screen and (min-width: 901px) {
  .main-header {
    background-color: rgb(1, 1, 3);
    height: 90vh;
  }
  .menu-phone {
    display: none !important;
  }
  .menu-phone .item-two {
    display: none !important;
  }
  .content-menu {
    width: 100%;
  }
  .content-menu .title-one {
    font-size: 38px;
    letter-spacing: 2.5px;
    font-weight: 200;
    text-align: center;
  }
  .content-menu .title-two {
    font-size: 80px;
    font-weight: 900;
    letter-spacing: -1px;
    text-align: center;
  }
  .content-menu .title-three {
    line-height: 28px;
    font-size: 18px;
    width: 500px;
    text-align: center;
    margin-top: 13px;
  }
  .intro-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    margin: 50px 0px;
  }
  .intro-container .intro-image img {
    width: 430px;
    -o-object-fit: cover;
       object-fit: cover;
  }
  .intro-container .intro-text {
    width: 430px;
    line-height: 23px;
    font-size: 13px;
  }
  .tech-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 50px 0px;
  }
  .tech-container .tech-image img {
    width: 480px;
    height: 400px;
    -o-object-fit: cover;
       object-fit: cover;
  }
  .tech-container .tech-text {
    width: 480px;
    height: 400px;
    line-height: 23px;
    font-size: 13px;
    background-color: rgb(175, 122, 15);
  }
  .tech-container .tech-text h2 {
    margin: 18px;
    color: white;
  }
  .tech-container .tech-text .separatorx {
    background-color: white;
    margin: 24px;
  }
  .tech-container .tech-text p {
    margin: 0px 18px;
    color: white;
  }
  .tech-container .tech-text .btn-goto-reverse {
    margin: 38px 18px;
  }
  .software {
    margin: 0px 0px;
    padding: 50px 0px;
    display: flex;
    background-image: url(../ASSETS/globe.png);
    background-size: auto;
    background-repeat: no-repeat;
    background-color: rgb(255, 255, 255);
  }
  .main-service {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100vw;
    gap: 20px;
    background-color: rgb(243, 243, 243);
    padding: 50px 0px;
  }
  .main-service .item:hover {
    border: 0.5px solid rgb(175, 122, 15);
    border-bottom: 4px solid rgb(214, 217, 9);
    background-color: white;
  }
  .main-service .item:hover .title {
    color: rgb(175, 122, 15);
  }
  .main-service .item:hover .separatorx {
    width: 160px;
    background-color: rgb(214, 217, 9);
  }
  .main-service .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 18px 10px;
    border: 0.5px solid rgb(175, 122, 15);
    border-top: 4px solid rgb(175, 122, 15);
    border-radius: 1px;
    width: 260px;
    cursor: pointer;
    transition: all 1s ease;
    background-color: rgb(249, 247, 239);
  }
  .main-service .item .separatorx {
    width: 90px;
    transition: 0.9s;
  }
  .main-service .item .icon {
    font-size: 24px;
    background-color: rgb(175, 122, 15);
    height: 70px;
    width: 70px;
    display: grid;
    place-content: center;
    border-radius: 100%;
    color: white;
  }
  .main-service .item .title {
    margin: 20px 10px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    width: 230px;
    transition: 1s;
  }
  .main-service .item .content {
    margin-top: 10px;
    text-align: center;
    font-size: 13px;
    color: gray;
    line-height: 20px;
  }
}
ANIMATION .circles {
  width: 100vw;
  height: 100vh;
  background-color: #0056b3;
}

.circles li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: rgba(181, 182, 180, 0.358);
  animation: animate 25s linear infinite;
  z-index: 10;
}

.circles li:nth-child(1) {
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}

.circles li:nth-child(2) {
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.circles li:nth-child(3) {
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}

.circles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.circles li:nth-child(5) {
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}

.circles li:nth-child(6) {
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}

.circles li:nth-child(7) {
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}

.circles li:nth-child(8) {
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.circles li:nth-child(9) {
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.circles li:nth-child(10) {
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 11s;
}

@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }
  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
}/*# sourceMappingURL=style.css.map */