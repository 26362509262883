$main-color: rgb(175, 122, 15);
.separatorx {
    height: 5px;
    background-color: $main-color;
    margin: 40px 0px;
}
.btn-goto label {
    font-size: 12px;
    margin-top: 0px;
    cursor: pointer;
}
.btn-goto {
    background-color: $main-color;
    width: max-content;
    padding: 10px 17px;
    color: white;
    font-weight: 700;
    cursor: pointer;
    transition: 0.9s;
    margin-top: 0px;
    display: flex;
    align-items: center;
    gap: 10px;
    border: 1px solid transparent;
}
.btn-goto-reverse:hover {
    background-color: rgba(24, 166, 24, 0.821);
    border: 1px solid white;
    color: white;
}
.btn-goto-reverse label {
    font-size: 12px;
    margin-top: 0px;
    cursor: pointer;
}
.btn-goto-reverse {
    background-color: white;
    border: 1px solid $main-color;
    color: $main-color;
    width: max-content;
    padding: 10px 17px;
    font-weight: 700;
    cursor: pointer;
    transition: 0.9s;
    margin-top: 0px;
    display: flex;
    align-items: center;
    gap: 10px;
}
.btn-goto:hover {
    background-color: white;
    border: 1px solid $main-color;
    color: $main-color;
}

.products-all {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgb(241, 241, 241);
    padding: 20px 0px;
    p {
        width: 430px;
        line-height: 23px;
        font-size: 13px;
        text-align: center;
    }
}
.products-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    // background-color: white;
    padding: 20px 0px;
}
.product:hover {
    border-bottom: 6px solid $main-color;
    border-top: 6px solid transparent;
    .content {
        display: flex;
    }
}
.product {
    width: 200px;
    height: 220px;
    text-align: center;
    background-color: white;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    border-bottom: 6px solid transparent;
    border-top: 6px solid $main-color;
    transition: 0.9s;
    cursor: pointer;
    .content {
        position: absolute;
        bottom: 0;
        width: 100%;
        background-color: rgba(255, 255, 255, 0.769) !important;
        display: none;
        justify-content: start;
        align-items: start;
        flex-direction: column;
        transition: 0.9s;
        .name {
            color: $main-color;
            margin: 10px 10px;
            font-weight: 700;
        }
    }
}
.software {
    background-image: url(../ASSETS/globe.png);
    background-size: auto;
    background-repeat: no-repeat;
    background-color: rgb(255, 255, 255);
}

.main-header {
    position: relative;
    width: 100%;
    height: 85vh;
    overflow: hidden;
    .bg-video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .menu-phone {
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 10;

        .item-one {
            padding: 20px 0px;
            background-color: rgba(1, 1, 1, 0.6);
            transition: 0.9s;
            display: flex;
            justify-content: space-between;
            .one {
                padding: 0px 30px;
                display: grid;
                place-content: center;
                .icon {
                    color: white;
                    cursor: pointer;
                }
                .logo {
                    background-color: transparent !important;
                }
            }
        }
        .item-two {
            background-color: white;
            flex-direction: column;
            transition: 0.9s;
            .item {
                padding: 20px 20px;
                font-size: 13px;
                font-weight: 400;
                cursor: pointer;
                border: 0.3px solid rgb(195, 193, 193);
                transition: 1s;
                font-weight: 900;
            }
            .item:hover {
                background-color: $main-color;
                color: white;
                font-weight: 400;
            }
        }
    }
    .menu-desktop {
        position: fixed;
        top: 0;
        background-color: rgba(10, 9, 9, 0.438);
        width: 100%;
        padding: 20px 0px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        z-index: 10;
        .logo {
            margin: 0px 30px;
        }
        .menu-all {
            margin: 0px 30px;
            display: flex;
            .item {
                padding: 7px 10px;
                font-size: 11px;
                font-weight: 400;
                cursor: pointer;
                color: white;
                transition: 1s;
                border-radius: 2px;
            }
            .item:hover {
                background-color: rgba(255, 255, 255, 0.335);
                color: white;
            }
            .active {
                background-color: rgba(255, 255, 255, 0.255);
                color: white;
                font-weight: 900;
            }
        }
    }
    .content-menu {
        position: absolute;
        bottom: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        justify-content: center;
        .title-one {
            color: white;
        }
        .title-two {
            color: white;
        }
        .title-three {
            color: white;
            letter-spacing: 1px;
        }
        .btn-four {
            color: white;
            margin: 30px;
            background-color: rgba(240, 248, 255, 0.288);
            padding: 18px;
            font-size: 12px;
            font-weight: 700;
            cursor: pointer;
            transition: 0.9s;
        }
        .btn-four:hover {
            background-color: rgba(1, 1, 1, 0.288);
            border-radius: 8px;
        }
    }
}

.tech-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 50px 0px;
    .tech-image img {
        width: 530px;
        height: 400px;
        object-fit: cover;
    }
    .tech-text {
        width: 530px;
        height: 400px;
        line-height: 23px;
        font-size: 13px;
        background-color: $main-color;
        h2 {
            margin: 18px;
            color: white;
        }
        .separatorx {
            background-color: white;
            margin: 24px;
        }
        p {
            margin: 0px 18px;
            color: white;
        }
        .btn-goto-reverse {
            margin: 38px 18px;
        }
    }
} // MAIN SERVICES
.main-service {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100vw;
    flex-wrap: wrap;
    gap: 20px;
    background-color: rgb(243, 243, 243);
    margin: 50px 0px;
    padding: 50px 0px;
    .item:hover {
        border: 0.5px solid $main-color;
        border-bottom: 4px solid rgb(214, 217, 9);
        background-color: white;
        .title {
            color: $main-color;
        }
        .separatorx {
            width: 160px;
            background-color: rgb(214, 217, 9);
        }
    }

    .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 18px 10px;
        border: 0.5px solid $main-color;
        border-top: 4px solid $main-color;
        border-radius: 1px;
        width: 260px;
        cursor: pointer;
        transition: all 1s ease;
        background-color: rgb(200, 200, 200);
        .separatorx {
            width: 90px;
            transition: 0.9s;
        }
        .icon {
            font-size: 24px;
            background-color: $main-color;
            height: 70px;
            width: 70px;
            display: grid;
            place-content: center;
            border-radius: 100%;
            color: white;
        }

        .title {
            margin: 20px 10px;
            font-size: 16px;
            font-weight: bold;
            text-align: center;
            width: 230px;
            transition: 1s;
        }

        .content {
            margin-top: 10px;
            text-align: center;
            font-size: 13px;
            color: gray;
            line-height: 20px;
        }
    }
}
.footer-all {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: black;
    .footer-one {
        padding: 20px;
        display: flex;
        justify-content: space-around;
        align-items: flex-start;
        .one {
            display: flex;
            justify-content: center;
            align-items: center;
            .text {
                font-size: 12px;
                color: rgb(192, 192, 192) !important;
                border-left: 1px solid gray;
                padding: 0px 20px;
                font-weight: 300;
                line-height: 20px;
            }
        }
        .footer-menu {
            display: flex;
            .item {
                color: white;
                font-size: 11px;
                font-weight: 800;
                padding: 10px;
                background-color: rgba(219, 242, 222, 0.184);
                margin: 0px 2px;
                cursor: pointer;
            }
            .item:hover {
                background-color: transparent;
            }
        }
    }
    .footer-two {
        font-size: 13px;
        padding: 20px;
        color: rgb(192, 192, 192) !important;
        border-top: rgb(68, 68, 68) solid 1.2px;
        .company {
            color: $main-color;
            cursor: pointer;
        }
    }
    .social {
        margin: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 0.9s;
        .icon {
            margin: 5px 10px;
            font-size: 20px;
            cursor: pointer;
        }
        .icon:hover {
            color: greenyellow;
        }
    }
}

.contacts {
    display: flex;
    align-items: center;
    flex-direction: column;
    // background-color: $main-color;
    background-color: black;
    justify-content: center;
    // margin-bottom: 1px;
    h2 {
        color: white;
    }
    .separatorx {
        background-color: white;
    }
    p {
        font-size: 13px;
        width: 700px;
        line-height: 22px;
        text-align: center;
        color: white;
    }
}
.contact-info {
    margin: 20px;
    padding: 10px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
}

.contact-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    margin: 10px 30px;

    img {
        height: 60px;
        border-radius: 100px;
        background-color: $main-color;
        border: 3px solid rgb(239, 239, 239);
    }
    h4 {
        // font-size: 12px;
        font-weight: 600;
        margin-top: 10px;
        color: white;
        width: 200px;
        // background-color: $main-color;
        text-align: center;
        padding: 10px;
        // color: white;
        margin-bottom: 40px;
    }
}

.testimonies {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px 0px;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: black;
    width: 100vw;
    position: relative;
    p {
        font-size: 12px;
        line-height: 24px;
        color: white;
        margin: 20px;
        font-weight: 200;
    }
    .text {
        background-color: transparent;
        border: 1px solid transparent;
        .title {
            font-size: 30px;
            font-weight: 700;
            color: white;
            margin: 20px;
        }

        .btn-goto {
            margin: 10px 20px;
        }
    }
    img {
        height: 100%;
        width: 100%;
        position: absolute;
        opacity: 0.2;
    }
    div {
        height: 300px;
        width: 330px;
        margin: 20px;
        z-index: 0;
        border: 1px solid white;
        border-radius: 7px;
        background: #dcdcdc28;
    }
    .testimony {
        .stars {
            color: rgb(217, 151, 20);
            display: flex;
            margin: 40px 20px;
            .icon {
                margin: 0px 5px;
            }
        }
        .customer {
            display: flex;
            margin: 0px 20px;
            .avatar {
                height: 67px;
                width: 67px;
                border-radius: 100px;
                background-color: black;
                // background-image: url(../ASSETS/salomon.png);
                background-repeat: no-repeat;
                background-size: cover;
            }
            .id {
                display: flex;
                flex-direction: column;
                justify-content: start;
                align-items: start;
                .name {
                    color: white;
                    margin: 0px 10px;
                    font-weight: 700;
                }
                .title {
                    color: white;
                    margin: 0px 10px;
                    font-weight: 300;
                    font-size: 13PX;
                    margin-top: 4px;
                }
            }
        }
    }
    
}


.products-all{position: relative;}
.device-details{
  position:fixed !important; 
  background-color: rgba(0, 0, 0, 0.321);
  bottom: 0;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  .device-component{
    width: 430px;
    float:right;
    height: 70vw;
    margin: 30px 0px;
    background-color: white !important;
    overflow-y: auto;
    padding: 20px;
    border-radius: 10px;
    .caracteristique{
      text-align: left;
      color: rgb(98, 98, 98);
      font-size: 11px;
    }
    .name{
      font-weight: 800;
      font-size: 25px;
      color: $main-color;

    }
    .image{
       width: 100px;
    }
    .element{
      margin-bottom: 10px;
      font-size: 11px;
      color: rgb(98, 98, 98);
      
    }
    strong{
        color: black;
    }

  }

}
/* Media query for phones (up to 600px) */
@media only screen and (max-width: 600px) {
    .contacts {
        p {
            width: auto;
            padding: 0px 30px;
        }
    }
    .contact-info {
        flex-direction: column;
    }
    .contact-item {
        img {
            height: 100px;
        }
    }
    .main-header {
        background-color: rgb(5, 5, 5);
        height: 79vh;
    }
    .menu-desktop {
        display: none !important;
    }
    .content-menu {
        width: 100%;
        .title-one {
            font-size: 30px;
            letter-spacing: 2.5px;
            font-weight: 200;
            text-align: center;
        }
        .title-two {
            font-size: 55px;
            font-weight: 900;
            letter-spacing: -1px;
            text-align: center;
        }
        .title-three {
            line-height: 18px;
            font-size: 12px;
            width: 400px;
            text-align: center;
            margin-top: 13px;
        }
    }
    // QUI SOMMES NOUS
    .intro-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        // background-color: #0056b3;
        // width: 300px;
        gap: 20px;
        margin: 60px 0px;
        .intro-image img {
            width: 300px;
            object-fit: cover;
        }
        .intro-text {
            width: 300px;
            line-height: 23px;
            font-size: 13px;
        }
    }
    .tech-container {
        flex-direction: column;
        .tech-image img {
            width: 400px;
            height: 400px;
            object-fit: cover;
        }
        .tech-text {
            height: max-content;
            line-height: 23px;
            font-size: 13px;
            background-color: $main-color;
            width: 400px;
            h2 {
                margin: 18px 40px;
                color: white;
            }
            .separatorx {
                background-color: white;
                margin: 24px 40px;
            }
            p {
                margin: 0px 40px;
                color: white;
            }
            .btn-goto-reverse {
                margin: 38px 40px;
            }
        }
        // MAIN SERVICES
        .main-service {
            display: flex;
            flex-direction: row;
            justify-content: center;
            width: 100vw;
            flex-wrap: wrap;
            gap: 20px;
            background-color: rgb(243, 243, 243);
            margin: 50px 0px;
            padding: 50px 0px;
            .item:hover {
                border: 0.5px solid $main-color;
                border-bottom: 4px solid rgb(214, 217, 9);
                background-color: white;
                .title {
                    color: $main-color;
                }
                .separatorx {
                    width: 160px;
                    background-color: rgb(214, 217, 9);
                }
            }

            .item {
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 18px 10px;
                border: 0.5px solid $main-color;
                border-top: 4px solid $main-color;
                border-radius: 1px;
                width: 260px;
                cursor: pointer;
                transition: all 1s ease;
                background-color: rgb(249, 247, 239);
                .separatorx {
                    width: 90px;
                    transition: 0.9s;
                }
                .icon {
                    font-size: 24px;
                    background-color: $main-color;
                    height: 70px;
                    width: 70px;
                    display: grid;
                    place-content: center;
                    border-radius: 100%;
                    color: white;
                }

                .title {
                    margin: 20px 10px;
                    font-size: 16px;
                    font-weight: bold;
                    text-align: center;
                    width: 230px;
                    transition: 1s;
                }

                .content {
                    margin-top: 10px;
                    text-align: center;
                    font-size: 13px;
                    color: gray;
                    line-height: 20px;
                }
            }
        }
        .product {
            width: 300px;
            height: 280px;
        }
    }
    .footer-all {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: s;
        background-color: black;
        .footer-one {
            flex-direction: column;
            .one {
                display: flex;
                justify-content: start;
                align-items: start;
                flex-direction: column;
                .text {
                    font-size: 12px;
                    color: rgb(192, 192, 192) !important;
                    border-left: 1px solid transparent;
                    padding: 20px;
                    font-weight: 300;
                    line-height: 20px;
                    margin-left: 10px;
                }
            }
            .footer-menu {
                display: flex;
                flex-direction: column;
                width: 100%;
                justify-content: center;
                align-items: center;
                .item {
                    width: 80%;
                    color: white;
                    font-size: 11px;
                    font-weight: 800;
                    padding: 20px;
                    background-color: rgba(219, 242, 222, 0.184);
                    margin: 1px;
                    cursor: pointer;
                }
                .item:hover {
                    background-color: transparent;
                }
            }
        }
        .footer-two {
            font-size: 13px;
            padding: 20px;
            color: rgb(192, 192, 192) !important;
            border-top: rgb(68, 68, 68) solid 1.2px;
            .company {
                color: $main-color;
                cursor: pointer;
            }
        }
    }
    .testimonies {flex-direction: column;}
    .contact-info {flex-direction: column;}
}
/* Media query for tablets (601px to 900px) */
@media only screen and (min-width: 601px) and (max-width: 900px) {
    .main-header {
        background-color: rgb(7, 11, 7);
        height: 80vh;
    }
    .menu-desktop {
        display: none !important;
    }
    .content-menu {
        width: 100%;
        .title-one {
            font-size: 30px;
            letter-spacing: 2.5px;
            font-weight: 200;
            text-align: center;
        }
        .title-two {
            font-size: 55px;
            font-weight: 900;
            letter-spacing: -1px;
            text-align: center;
        }
        .title-three {
            line-height: 18px;
            font-size: 13px;
            width: 500px;
            text-align: center;
            margin-top: 13px;
        }
    }
    // QUI SOMMES NOUS
    .intro-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;
        margin: 60px 0px;

        .intro-image img {
            width: 430px;
            object-fit: cover;
        }
        .intro-text {
            width: 430px;
            line-height: 23px;
            font-size: 13px;
        }
    }
    .tech-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin: 50px 0px;
        .tech-image img {
            width: 530px;
            height: 400px;
            object-fit: cover;
        }
        .tech-text {
            width: 530px;
            height: 400px;
            line-height: 23px;
            font-size: 13px;
            background-color: $main-color;
            h2 {
                margin: 18px;
                color: white;
            }
            .separatorx {
                background-color: white;
                margin: 24px;
            }
            p {
                margin: 0px 18px;
                color: white;
            }
            .btn-goto-reverse {
                margin: 38px 18px;
            }
        }
    }

    // MAIN SERVICES
    .main-service {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100vw;
        flex-wrap: wrap;
        gap: 20px;
        background-color: rgb(243, 243, 243);
        margin: 50px 0px;
        padding: 50px 0px;
        .item:hover {
            border: 0.5px solid $main-color;
            border-bottom: 4px solid rgb(214, 217, 9);
            background-color: white;
            .title {
                color: $main-color;
            }
            .separatorx {
                width: 160px;
                background-color: rgb(214, 217, 9);
            }
        }

        .item {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 18px 10px;
            border: 0.5px solid $main-color;
            border-top: 4px solid $main-color;
            border-radius: 1px;
            width: 260px;
            cursor: pointer;
            transition: all 1s ease;
            background-color: rgb(249, 247, 239);
            .separatorx {
                width: 90px;
                transition: 0.9s;
            }
            .icon {
                font-size: 24px;
                background-color: $main-color;
                height: 70px;
                width: 70px;
                display: grid;
                place-content: center;
                border-radius: 100%;
                color: white;
            }

            .title {
                margin: 20px 10px;
                font-size: 16px;
                font-weight: bold;
                text-align: center;
                width: 230px;
                transition: 1s;
            }

            .content {
                margin-top: 10px;
                text-align: center;
                font-size: 13px;
                color: gray;
                line-height: 20px;
            }
        }
    }
    .footer-all {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: s;
        background-color: black;
        .footer-one {
            flex-direction: column;
            .one {
                display: flex;
                justify-content: start;
                align-items: start;
                flex-direction: column;
                .text {
                    font-size: 12px;
                    color: rgb(192, 192, 192) !important;
                    border-left: 1px solid transparent;
                    padding: 20px;
                    font-weight: 300;
                    line-height: 20px;
                    margin-left: 10px;
                }
            }
            .footer-menu {
                display: flex;
                flex-direction: column;
                width: 100%;
                justify-content: center;
                align-items: center;
                .item {
                    width: 80%;
                    color: white;
                    font-size: 11px;
                    font-weight: 800;
                    padding: 20px;
                    background-color: rgba(219, 242, 222, 0.184);
                    margin: 1px;
                    cursor: pointer;
                }
                .item:hover {
                    background-color: transparent;
                }
            }
        }
        .footer-two {
            font-size: 13px;
            padding: 20px;
            color: rgb(192, 192, 192) !important;
            border-top: rgb(68, 68, 68) solid 1.2px;
            .company {
                color: $main-color;
                cursor: pointer;
            }
        }
    }   .testimonies {flex-direction: column;} .contact-info {flex-direction: column;}
}

/* Media query for desktops (901px and above) */
@media only screen and (min-width: 901px) {
    .main-header {
        background-color: rgb(1, 1, 3);
        height: 90vh;
    }
    .menu-phone {
        display: none !important;
        .item-two {
            display: none !important;
        }
    }
    .content-menu {
        width: 100%;
        .title-one {
            font-size: 38px;
            letter-spacing: 2.5px;
            font-weight: 200;
            text-align: center;
        }
        .title-two {
            font-size: 80px;
            font-weight: 900;
            letter-spacing: -1px;
            text-align: center;
        }
        .title-three {
            line-height: 28px;
            font-size: 18px;
            width: 500px;
            text-align: center;
            margin-top: 13px;
        }
    }

    // QUI SOMMES NOUS
    .intro-container {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 30px;
        margin: 50px 0px;
        .intro-image img {
            width: 430px;
            object-fit: cover;
        }
        .intro-text {
            width: 430px;
            line-height: 23px;
            font-size: 13px;
        }
    }
    .tech-container {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 50px 0px;
        .tech-image img {
            width: 480px;
            height: 400px;
            object-fit: cover;
        }
        .tech-text {
            width: 480px;
            height: 400px;
            line-height: 23px;
            font-size: 13px;
            background-color: $main-color;
            h2 {
                margin: 18px;
                color: white;
            }
            .separatorx {
                background-color: white;
                margin: 24px;
            }
            p {
                margin: 0px 18px;
                color: white;
            }
            .btn-goto-reverse {
                margin: 38px 18px;
            }
        }
    }

    .software {
        // background-color: $main-color;
        margin: 0px 0px;
        padding: 50px 0px;
        display: flex;
        background-image: url(../ASSETS/globe.png);
        background-size: auto;
        background-repeat: no-repeat;
        background-color: rgb(255, 255, 255);
    }

    // MAIN SERVICES
    .main-service {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100vw;
        gap: 20px;
        background-color: rgb(243, 243, 243);
        // margin-top: 50px;
        padding: 50px 0px;
        .item:hover {
            border: 0.5px solid $main-color;
            border-bottom: 4px solid rgb(214, 217, 9);
            background-color: white;
            .title {
                color: $main-color;
            }
            .separatorx {
                width: 160px;
                background-color: rgb(214, 217, 9);
            }
        }

        .item {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 18px 10px;
            border: 0.5px solid $main-color;
            border-top: 4px solid $main-color;
            border-radius: 1px;
            width: 260px;
            cursor: pointer;
            transition: all 1s ease;
            background-color: rgb(249, 247, 239);
            .separatorx {
                width: 90px;
                transition: 0.9s;
            }
            .icon {
                font-size: 24px;
                background-color: $main-color;
                height: 70px;
                width: 70px;
                display: grid;
                place-content: center;
                border-radius: 100%;
                color: white;
            }

            .title {
                margin: 20px 10px;
                font-size: 16px;
                font-weight: bold;
                text-align: center;
                width: 230px;
                transition: 1s;
            }

            .content {
                margin-top: 10px;
                text-align: center;
                font-size: 13px;
                color: gray;
                line-height: 20px;
            }
        }
    }
}

ANIMATION .circles {
    width: 100vw;
    height: 100vh;
    background-color: #0056b3;
    // overflow: hidden;
}

.circles li {
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: rgba(181, 182, 180, 0.358);
    animation: animate 25s linear infinite;
    z-index: 10;
}

.circles li:nth-child(1) {
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
}

.circles li:nth-child(2) {
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;
}

.circles li:nth-child(3) {
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
}

.circles li:nth-child(4) {
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;
}

.circles li:nth-child(5) {
    left: 65%;
    width: 20px;
    height: 20px;
    animation-delay: 0s;
}

.circles li:nth-child(6) {
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
}

.circles li:nth-child(7) {
    left: 35%;
    width: 150px;
    height: 150px;
    animation-delay: 7s;
}

.circles li:nth-child(8) {
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 15s;
    animation-duration: 45s;
}

.circles li:nth-child(9) {
    left: 20%;
    width: 15px;
    height: 15px;
    animation-delay: 2s;
    animation-duration: 35s;
}

.circles li:nth-child(10) {
    left: 85%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 11s;
}

@keyframes animate {
    0% {
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }

    100% {
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }
}
